import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "tooltip"
    }}>{`Tooltip`}</h1>
    <p>{`Documentation and examples for adding custom tooltips with CSS and JavaScript using CSS3 for animations.`}</p>
    <div className='box'>
  <strong>Attention</strong>: the use of the components needs the <span className='pink'>squid.js</span> included in the project
    </div>
    <div className='box'>
  <strong>Attention + 1</strong>: Probably the interaction via Javascript should not work well in SPA applications (independent of the framework React, Angular, Vuejs, etc). For now we have no implementation but we will create in the future, for now just use the styles and create your own implementation
    </div>
    <h2 {...{
      "id": "light"
    }}>{`Light`}</h2>
    <div className='button button-pink display-inline-block' data-tooltip-theme='light' data-tooltip='Hello World!'>
  Mouse Over Here
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div
  data-tooltip='Some Text'
  data-tooltip-theme='light'
>
  <!-- HTML CONTENT -->
</div>
`}</code></pre>
    <h2 {...{
      "id": "dark"
    }}>{`Dark`}</h2>
    <div className='button button-pink display-inline-block' data-tooltip='Hello World!'>
  Mouse Over Here
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div
  data-tooltip='Some Text'
>
  <!-- HTML CONTENT -->
</div>
`}</code></pre>
    <h3 {...{
      "id": "attributes"
    }}>{`Attributes`}</h3>
    <h2 {...{
      "id": "trigger"
    }}>{`Trigger`}</h2>
    <p>{`The attribute `}<inlineCode parentName="p">{`data-tooltip-trigger`}</inlineCode>{` accept `}<inlineCode parentName="p">{`click`}</inlineCode>{` value. To use mouseover just leave it empty`}</p>
    <h2 {...{
      "id": "position"
    }}>{`Position`}</h2>
    <p>{`The attribute `}<inlineCode parentName="p">{`data-tooltip-position`}</inlineCode>{` accept:`}</p>
    <pre><code parentName="pre" {...{}}>{`right top
right center
right bottom
center top
center center
center bottom
left top
left center
left bottom
`}</code></pre>
    <h2 {...{
      "id": "theme"
    }}>{`Theme`}</h2>
    <p>{`The attribute `}<inlineCode parentName="p">{`data-tooltip-theme`}</inlineCode>{` accept `}<inlineCode parentName="p">{`dark`}</inlineCode>{` (or empty) and `}<inlineCode parentName="p">{`light`}</inlineCode>{` values`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      